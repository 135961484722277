import { Dialog } from '~/core/ui/Dialog'
import { IPlacement } from '../placement'
import { ICreateEditPlacement } from '../schema'
import PlacementForm from './PlacementForm'
import IconWrapper from '~/core/ui/IconWrapper'
import { useMutation } from 'urql'
import { editPlacementMutation } from './graphql/mutation-edit-placement'
import createModalHook from '~/lib/hooks/create-modal-hooks'
import { formatISO } from 'date-fns'
import { useMemo } from 'react'
import { Button } from '~/core/ui/Button'
import { useTranslation } from 'react-i18next'
import useBoundStore from '~/lib/store'
import ToolTipOnOverflow from './ToolTipOnOverflow'
import { Tooltip } from '~/core/ui/Tooltip'
import usePlacementStatusWithDotColor from '../hooks/use-placement-status-with-dot-color'
import { ISelectOption } from '~/core/ui/Select'
export const removeEmptyField = <T extends object>(obj: T): Partial<T> => {
  //@ts-expect-error
  return Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => v !== undefined && v !== null)
  )
}
const EditPlacementDialog = (props: {
  open: boolean
  setOpen: (open: boolean) => void
  onSubmit?: (data: ICreateEditPlacement) => Promise<IPlacement>
  openParams?: {
    onPlacementEdited: (placement: IPlacement) => void
    header?: { candidateName?: string; jobTitle?: string; companyName?: string }
    placement?: IPlacement
  }
}) => {
  const { t } = useTranslation()
  const { open, setOpen, onSubmit, openParams } = props
  const typeOfStatus = usePlacementStatusWithDotColor()
  const defaultValue = useMemo<ICreateEditPlacement | undefined>(
    () =>
      openParams?.placement
        ? ({
            id: openParams?.placement?.id?.toString(),
            applicantId: openParams?.placement?.applicant?.id.toString(),
            status: typeOfStatus.find(
              (item: ISelectOption) =>
                item.value === openParams?.placement?.status
            ),
            jobStageId:
              openParams?.placement?.applicant?.jobStage.id.toString(),

            hiredDate:
              openParams?.placement?.hiredDate &&
              new Date(openParams?.placement?.hiredDate),
            ...removeEmptyField({
              onboardDate:
                openParams?.placement?.onboardDate &&
                new Date(openParams?.placement?.onboardDate),
              endOfProbationDate:
                openParams?.placement?.endOfProbationDate &&
                new Date(openParams?.placement?.endOfProbationDate),
              salary: openParams?.placement?.salary?.toString()
            }),
            typeOfSalary: openParams?.placement?.typeOfSalary,
            currencyOfSalary: openParams?.placement?.currencyOfSalary,
            fee: openParams?.placement?.fee?.toString(),
            typeOfFee: openParams?.placement?.typeOfFee,
            revenue: openParams?.placement?.revenue?.toString(),
            currencyOfRevenue: openParams?.placement?.currencyOfRevenue,
            profitSplits:
              openParams?.placement?.profitSplits?.map((item) => ({
                id: +item.id,
                user_id: {
                  avatar: item.user?.avatar,
                  avatarVariants: item.user?.avatarVariants,
                  value: item.user?.id?.toString(),
                  supportingObj: { name: item.user?.fullName }
                },
                profit_percentage: item.profitPercentage.toString()
              })) || []
          } as ICreateEditPlacement)
        : undefined,
    [openParams?.placement]
  )

  return (
    <Dialog
      open={open}
      onOpenChange={setOpen}
      isDivider={true}
      isPreventAutoFocusDialog={true}
      size="sm"
      label={`${t('placements:editPlacement')}`}
      description={
        <div>
          <div className="flex items-center">
            <div className="mr-2">
              <IconWrapper size={16} name="User" />
            </div>
            <Tooltip content={openParams?.header?.candidateName}>
              <div className="line-clamp-1 text-sm text-gray-900">
                {openParams?.header?.candidateName}
              </div>
            </Tooltip>
          </div>
          <div className="mt-1.5 flex items-center">
            <div className="mr-2 shrink-0">
              <IconWrapper size={16} name="Briefcase" />
            </div>
            <ToolTipOnOverflow
              text={openParams?.header?.jobTitle}
              className="max-w-[200px] shrink-0 truncate text-sm text-gray-900"
            />
            <div className="ml-2 mr-2 h-0.5 w-0.5 shrink-0  rounded-full bg-gray-400"></div>
            <ToolTipOnOverflow
              className="shrink-1 truncate"
              text={openParams?.header?.companyName}
            />
          </div>
        </div>
      }>
      {defaultValue && (
        <PlacementForm
          onSubmit={(data) => {
            return onSubmit
              ? onSubmit(data).then((placement) => {
                  setOpen(false)
                  openParams?.onPlacementEdited &&
                    openParams?.onPlacementEdited(placement)
                })
              : Promise.reject('Handler not provided')
          }}
          defaultValue={defaultValue}
          applicant={openParams?.placement?.applicant}>
          <div className="mt-6 flex items-center justify-end space-x-3">
            <Button
              label={`${t('button:cancel')}`}
              size="sm"
              type="secondary"
              onClick={() => setOpen(false)}
            />
            <Button
              size="sm"
              label={`${t('interview:email_modal:save')}`}
              htmlType="submit"
            />
          </div>
        </PlacementForm>
      )}
    </Dialog>
  )
}
export const useEditPlacementModel = () => {
  const { t } = useTranslation()
  const [{ fetching: creatingRequisition }, editPlacement] = useMutation(
    editPlacementMutation
  )
  const { setToast } = useBoundStore()
  const {
    ModalComponent: EditPlacementModalComponent,
    openModal: openEditPlacementModel
  } = createModalHook(EditPlacementDialog)((data) => {
    return editPlacement({
      id: data.id ? +data.id : undefined,
      applicantId: +data.applicantId,
      jobStageId: +data.jobStageId,
      status: data.status?.value,
      hiredDate: formatISO(data.hiredDate),
      onboardDate: data.onboardDate ? formatISO(data.onboardDate) : null,
      endOfProbationDate: data.endOfProbationDate
        ? formatISO(data.endOfProbationDate)
        : null,
      salary: data.salary ? +data.salary : null,
      typeOfSalary: data.typeOfSalary,
      currencyOfSalary: 'USD',
      fee: data.fee ? +data.fee : null,
      typeOfFee: data.typeOfFee,
      revenue: data.revenue ? +data.revenue : null,
      currencyOfRevenue: data.currencyOfRevenue && data.currencyOfRevenue,
      profitSplits: data.profitSplits.map((item) => ({
        userId: +item.user_id?.value,
        profitPercentage: +item.profit_percentage,
        id: item.id ? +item.id : undefined,
        _destroy: item._destroy
      }))
    }).then((rs) => {
      if (rs.error) {
        throw rs.error
      }
      setToast({
        open: true,
        type: 'success',
        title: `${t('notification:placementUpdated')}`,
        classNameConfig: {
          viewport: 'mb-[48px]'
        }
      })
      return rs.data?.placementsUpdate.placement
    })
  })

  return {
    EditPlacementModalComponent,
    openEditPlacementModel
  }
}
